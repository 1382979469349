import { Link } from 'gatsby'
import { FC } from 'react'
import styled from 'styled-components'

import { Title } from '../../common/styles'
import Layout from '../../layouts'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessTitle = styled(Title)`
  margin-top: 28px;
`

const Message = styled.div`
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.p`
  line-height: 21px;
  text-align: center;
`

const SuccessPage: FC = () => {
  return (
    <Layout smallNavBar>
      <Container>
        <SuccessTitle>Votre message a bien été envoyé !</SuccessTitle>
        <Message>
          <Text>
            Je reviens vers vous au plus vite.
            <br />Il peut arriver que ma réponse atterrisse dans vos spams 📬...
            <br />En cas de doute, n'hésitez pas à vérifier dans vos indésirables.
            <br />À Bientôt !
          </Text>
        </Message>
        <Link to='/'>Retourner à l'accueil</Link>
      </Container>
    </Layout>
  )
}

export default SuccessPage
